<template>
  <el-dialog
    :width="geted ? '900px' : '500px'"
    class="export-excel-popup"
    title="Export dữ liệu ra excel"
    :visible="visible"
    :show-close="!exporting"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
  >
    <el-form v-if="!geted" label-position="top">
      <el-form-item label="Tiêu đề file">
        <el-select v-model="activeTab" :disabled="exporting">
          <el-option
            v-for="item in titles"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="`Số dòng (${limit}/${total})`">
        <el-input-number
          v-model="limit"
          :min="1"
          :max="total"
          :disabled="exporting"
        ></el-input-number>
      </el-form-item>
    </el-form>
    <div v-else>
      <h3 class="text-center">{{ excelTitle }} ({{ exportData.length }})</h3>
      <el-table
        :data="exportData"
        style="width: 100%"
      >
        <el-table-column
          v-for="column in excelColums"
          :key="column.key"
          :prop="column.key"
          :label="column.label"
          :width="column.width"
        >
          <template slot-scope="scope">
            <div class="text-limited" :title="scope.row[column.key]">
              {{ scope.row[column.key] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer">
      <el-button
        v-if="!geted"
        type="primary"
        :loading="exporting"
        @click="getExportData"
      >
        Tiếp tục
      </el-button>
      <el-row v-else type="flex" align="middle" justify="end">
        <el-button
          type="info"
          class="mr-2"
          :loading="exporting"
          @click="geted = false"
        >
          Quay lại
        </el-button>
        <download-excel
          :data="excelData"
          :header="excelHeader"
          name="comments.xls"
        >
          <el-button
            type="primary"
            icon="el-icon-download"
            :loading="exporting"
          >
            Export
          </el-button>
        </download-excel>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { listTours } from '@/services/tour'
import { listPlaces } from '@/services/place'
import { listPosts } from '@/services/post'
import { getComments } from '@/services/comment'
import { converData } from '@/utils/filters'

import TYPE from '@/data/type'

export default {
  name: 'ExportExcelPopup',
  components: {},
  props: {
    visible: Boolean,
    order: String,
    total: Number,
    columns: Array,
    type: String,
    titles: Array,
    detail: Object,
    activeTab: [String, Boolean]
  },
  data () {
    return {
      exporting: false,
      limit: 10,
      exportData: [],
      geted: false
    }
  },
  computed: {
    ...mapGetters(['language']),
    placeType() {
      return TYPE.PLACES.find((p) => p.value === this.type)
    },
    excelTitle() {
      return this.titles.find((t) => t.value === this.activeTab).label
    },
    excelColums() {
      return this.columns.filter((colum) => colum.key !== 'actions')
    },
    requestParams() {
      let params = {
        locale: this.language,
        page: 1,
        per_page: this.limit,
        order: this.order
      }
      if (this.type === 'comment') {
        params = { ...params, approved: this.activeTab }
      } else {
        if (this.placeType.type === 'tour') {
          params = { ...params, tour_type: this.type }
        } else if (this.placeType.type === 'post') {
          params = { ...params }
        } else {
          params = { ...params, place_types: this.type }
        }
        if (!this.children) {
          params = { ...params, only_parent: true }
        } else {
          params = { ...params, only_children: true }
          if (this.detail && this.detail.type === this.type) {
            params = { ...params, parent_id: this.detail.id }
          }
        }
      }
      if (this.detail && this.detail.type !== this.type) {
        params = { ...params, [`${this.detail.type}_id`]: this.detail.id }
      }
      return params
    },
    excelHeader() {
      return [`Danh sách : ${this.excelTitle.toLowerCase()}`, `Số lượng bản ghi: ${this.total}`]
    },
    excelData() {
      return this.exportData.map(d => {
        const data = {}
        this.excelColums.forEach((column) => {
          data[column.label] = d[column.key]
        })
        return data
      })
    }
  },
  methods: {
    getExportData() {
      const params = this.requestParams
      this.exporting = true
      let request = null
      if (this.type === 'comment') {
        request = getComments(params)
      } else if (this.placeType.type === 'tour') {
        request = listTours(params)
      } else if (this.placeType.type === 'post') {
        request = listPosts(params)
      } else {
        request = listPlaces(params)
      }
      request.then((response) => {
        this.exportData = response.result.map((r, index) => converData({ ...r, stt: index + 1 }, this.excelColums))
        this.exporting = false
        this.geted = true
      }).catch(() => {
        this.exporting = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.export-excel-popup {
  .el-select, .el-input-number {
    width: 100%;
  }
}
</style>
<style lang="scss">
.export-excel-popup {
  .el-dialog__body {
    max-height: 400px;
    overflow: auto;
  }
}
</style>
